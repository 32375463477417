<template>
    <div class="c-SectionFive u-relative u-fit-w" v-bind:class="{ 'is-page-ready': isPageReady, 'is-from-sections': isFromSections, 'is-from-section': isFromSection }">

        <sub-header v-bind:active="isPageReady" v-bind:number="5" v-bind:title="'Jobs and Artificial Intelligence'"></sub-header>

        <section class="js-section js-ui-switch js-nav-section c-SectionFive-intro u-relative u-fit-w u-viewport-fit-h u-color--white u-overflow-h" v-bind:style="{ height: minHeight +'px' }">
            <parallax-object v-bind:active="isPageReady" v-bind:ratio="-0.8" class="c-Container u-absolute u-pos-tl u-fit u-flex u-align-items-c | u-align-items-fe@sm">
                <object-fit v-bind:width="1280" v-bind:height="720"  v-bind:width-mobile="374" v-bind:height-mobile="664" class="c-SectionFive-intro-video u-bg--blue u-fixed u-pos-tl u-fit">
                    <video-observer class="u-absolute u-pos-tl u-fit">
                        <video-responsive>
                            <video class="js-object-fit js-video u-absolute u-pos-tl u-fit" v-bind:poster="isMobile ? `${publicPath}static/images/five/poster-intro-mobile.jpg` : `${publicPath}static/images/five/poster-intro.jpg`" autoplay loop muted playsinline>
                                <source :src="`${publicPath}static/videos/section5.webm`" type="video/webm">
                                <source :src="`${publicPath}static/videos/section5.mp4`" type="video/mp4">

                                <source :srcset="`${publicPath}static/videos/section5-mobile.webm`" type="video/webm" media="(max-width: 767px)">
                                <source :srcset="`${publicPath}static/videos/section5-mobile.mp4`" type="video/mp4" media="(max-width: 767px)">
                            </video>
                        </video-responsive>
                    </video-observer>
                </object-fit>

                <div class="u-relative u-w10of16 u-offset-l-w4of16 u-force-3d | u-w13of16@md u-offset-l-w2of16@md | u-fit-w@sm u-marg-l-0@sm">
                    <h1 class="c-SectionFive-intro-title t-h1">
                        <span class="c-SectionFive-line c-SectionFive-line--0 c-SectionFive-intro-title-line-1 u-block">Jobs and</span>
                        <span class="c-SectionFive-line c-SectionFive-line--1 c-SectionFive-intro-title-line-2 u-block u-offset-l-w4of10 | u-offset-l-w5of16@sm">Artificial</span>
                        <span class="c-SectionFive-line c-SectionFive-line--2 c-SectionFive-intro-title-line-3 u-hide | u-block@sm u-offset-l-w2of16@sm">Intelligence</span>
                    </h1>

                    <div class="u-relative u-flex u-fit-w">
                        <div class="u-text-right u-w7of10 u-pad-r-lg | u-hide@sm">
                            <span class="c-SectionFive-line c-SectionFive-line--2 c-SectionFive-intro-title-line-3 t-h1 u-block">Intelligence</span>
                        </div>

                        <div class="c-SectionFive-intro-desc u-w3of10 | u-w6of8@sm u-offset-l-w1of8@sm">
                            <div class="c-SectionFive-line c-SectionFive-line--3">
                                <span class="t-text--xxs u-font-bold u-uppercase u-flex u-align-items-c">
                                    <svg class="c-SectionFive-intro-icon u-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" width="12" height="12">
                                        <path fill="#FFFFFF" d="M10.9 7.9c-.6-.8-1.4-1.5-2.3-1.9.9-.4 1.7-1.1 2.3-1.9.2-.3.4-.5.5-.8v-.2c-.1-.3-.3-.5-.5-.8l-.2-.3-.1.3c-.1.3-.2.5-.4.8C9.3 4.5 7.7 5.4 6 5.4c-1.7 0-3.3-.9-4.2-2.3-.2-.3-.3-.5-.4-.8L1.3 2l-.2.3c-.2.3-.4.5-.6.8v.2c.1.3.3.6.5.8.7.8 1.5 1.5 2.4 1.9-.9.4-1.7 1.1-2.3 1.9-.2.3-.4.5-.6.8v.2c.1.3.3.5.5.8l.3.3.1-.3c.1-.3.2-.5.4-.8.9-1.4 2.5-2.3 4.2-2.3 1.7 0 3.3.9 4.2 2.3.2.3.3.5.4.8l.1.3.2-.3c.2-.3.4-.5.5-.8v-.2c-.1-.3-.3-.5-.5-.8z"/>
                                        <circle fill="#FFFFFF" cx="6" cy="1.2" r="1.2"/>
                                        <circle fill="#FFFFFF" cx="6" cy="10.8" r="1.2"/>
                                    </svg>
                                    <span>section 05</span>
                                </span>
                            </div>

                            <p class="c-SectionFive-line c-SectionFive-line--4 c-SectionFive-intro-text t-text--sm">Workforce concerns mount. Artificial Intelligence adaption skyrockets.</p>
                        </div>
                    </div>
                </div>
                <div class="c-SectionFive-intro-scroll c-Container u-absolute u-pos-bl u-fit-w">
                    <cursor-trigger v-bind:name="'scroll'" class="u-inline-block u-offset-l-w2of16 ">
                        <button v-on:click="onScroll" type="button" class="c-SectionFive-intro-scroll-btn c-ScrollBtn t-btn t-text--xxs u-font-bold">SCROLL</button>
                    </cursor-trigger>
                </div>
            </parallax-object>
        </section>

        <section class="js-section js-ui-switch js-nav-section c-SectionFive-dataOne c-Container u-relative u-bg--white">
            <h2 class="t-h3 u-w5of16 u-offset-l-w6of16 | u-offset-l-w5of16@md | u-w6of8@sm u-offset-l-w1of8@sm">worry over the workforce</h2>

            <p class="c-SectionFive-dataOne-desc t-h4 u-w7of16 u-offset-l-w2of16 | u-w8of16@md u-offset-l-w1of16@md | u-w14of16@sm u-offset-l-w1of16@sm">Mobility of people, the transfer of technology and talent flow are some of the top fears Business Executives have when it comes to innovation.</p>

            <div class="c-SectionFive-dataOne-head u-flex u-flex-flow-row-wrap">
                <div class="u-w3of16 u-offset-l-w6of16 | u-offset-l-w5of16@lg | u-w11of16@sm u-offset-l-w1of16@sm">
                    <div ref="numberOne" class="c-SectionFive-dataOne-number u-color--blue">
                        <div v-bind:class="'is-number-'+numberOne" class="u-relative u-inline-block">
                            <span v-for="n in 20" v-bind:key="n" v-bind:class="n != 20 ? 'u-absolute u-pos-tl' : ''" class="c-SectionFive-dataOne-number-item">{{65-(20-n)}}<sup>%</sup></span>
                        </div>
                    </div>
                </div>
                <div class="u-w3of16 u-offset-l-w1of16 | u-w4of16@lg | u-w5of16@md | u-fit-w@sm u-marg-l-0@sm">
                    <div class="u-w11of16@sm u-offset-l-w1of16@sm">
                        <p class="t-text--sm">of Business Executives think that those over 55 years of age who are retiring soon will have a negative impact on innovation.</p>
                    </div>
                    <div class="u-marg-t-sm | u-w14of16@sm u-offset-l-w1of16@sm u-marg-t-md@sm u-relative@sm">
                        <question-btn v-bind:right="true" number="17" question="How much do you agree or disagree with the following statements?" response="(NET Agree) Base: Business Executives 2020: 2,307)" />
                        <share-btn v-bind:right="true" class="u-marg-l-xs" />
                    </div>
                </div>
            </div>

            <div class="u-relative u-fit-w">
                <div class="c-SectionFive-dataOne-video u-w10of16 u-offset-l-w5of16 | u-w12of16@md u-offset-l-w3of16@md | u-fit-w@sm u-marg-l-0@sm">
                    <video-observer class="c-SectionFive-dataOne-video-container t-b-radius-lg">
                        <video-responsive>
                            <video class="js-video u-absolute u-pos-tl u-fit" :poster="`${publicPath}static/images/five/workforce-poster.jpg`" preload="auto" loop muted playsinline>
                                <source :src="`${publicPath}static/videos/five/workforce.webm`" type="video/webm">
                                <source :src="`${publicPath}static/videos/five/workforce.mp4`" type="video/mp4">

                                <source :srcset="`${publicPath}static/videos/five/workforce-mobile.webm`" type="video/webm" media="(max-width: 767px)">
                                <source :srcset="`${publicPath}static/videos/five/workforce-mobile.mp4`" type="video/mp4" media="(max-width: 767px)">
                            </video>
                        </video-responsive>
                    </video-observer>
                </div>

                <parallax-object v-bind:ratio="0.1" class="c-SectionFive-dataOne-img u-w5of16 u-offset-l-w2of16 u-absolute u-pos-bl | u-offset-l-w0of16@md | u-w13of16@sm u-offset-l-w1of16@sm">
                    <div class="c-SectionFive-dataOne-img-inner t-b-radius-md">
                        <img :src="`${publicPath}static/images/five/1.jpg`" alt="" class="u-absolute u-pos-tl u-fit">
                    </div>
                </parallax-object>

                <parallax-object v-bind:ratio="0.2" class="c-SectionFive-dataOne-gradient u-w2of16 u-offset-l-w3of16 u-absolute u-pos-tl | u-offset-l-w1of16@md | u-w4of16@sm u-offset-l-w11of16@sm">
                    <div class="c-SectionFive-dataOne-gradient-inner t-b-radius-sm"></div>
                </parallax-object>
            </div>

            <div class="c-SectionFive-dataOne-foot u-flex u-flex-flow-row-wrap">
                <div class="u-w3of16 u-offset-l-w6of16 | u-offset-l-w5of16@lg | u-w11of16@sm u-offset-l-w1of16@sm">
                    <div ref="numberTwo" class="c-SectionFive-dataOne-number u-color--blue">
                        <div v-bind:class="'is-number-'+numberTwo" class="u-relative u-inline-block">
                            <span v-for="n in 20" v-bind:key="n" v-bind:class="n != 20 ? 'u-absolute u-pos-tl' : ''" class="c-SectionFive-dataOne-number-item">{{75-(20-n)}}<sup>%</sup></span>
                        </div>
                    </div>
                </div>
                <div class="u-w3of16 u-offset-l-w1of16 | u-w4of16@lg | u-w5of16@md | u-fit-w@sm u-marg-l-0@sm">
                    <div class="u-w11of16@sm u-offset-l-w1of16@sm">
                        <p class="t-text--sm">are concerned where new and skilled talent are going to come from.</p>
                        <p class="t-text--sm u-marg-t-sm">57% of Business Executives think STEM students aren’t being taught the skills they will need to drive innovation.</p>
                    </div>
                    <div class="u-marg-t-sm | u-w14of16@sm u-offset-l-w1of16@sm u-marg-t-md@sm u-relative@sm">
                        <question-btn v-bind:right="true" number="39" question="Thinking about recent STEM graduates that you see coming into your industry or your company how do you feel about the skills they have to drive innovation?" response="(Top 3 Box) [NEW JAN 2020 BE] Base: Business executives Jan 2020: 2,307; Business executives Sept 2020: 1,128" />
                        <share-btn v-bind:right="true" class="u-marg-l-xs" />
                    </div>
                </div>
            </div>

            <div class="u-w7of16 u-offset-l-w6of16 | u-offset-l-w5of16@lg | u-w8of16@md | u-w14of16@sm u-offset-l-w1of16@sm">
                <p class="c-SectionFive-dataOne-desc c-SectionFive-dataOne-desc--alt t-h4">Only half of STEM students (49%) believe they have the necessary skills to enter the workforce. Many Business Executives (71%) are concerned that the shift to remote learning due to COVID-19 affects students.</p>

                <div class="u-marg-t-sm u-flex u-flex-flow-row-wrap | u-marg-t-md@sm u-relative@sm">
                    <question-btn number="30" question="To what extent do you agree with the following statements about your generation of STEM graduates and students who will be entering the workforce soon?" response="(NET Agree) Base: STEM students: 1,004)" />
                    <question-btn class="u-marg-l-xs" number="56" question="Thinking about the impact COVID-19 will have on the educational sector, to what extent do you agree or disagree with the following statements?" response="(NET Agree) [NEW QUESTION SEPT 2020 BE] Base: Business executives Sept 2020: 1,128" />

                    <div class="u-flex@sm u-relative@sm u-fit-w@sm u-marg-t-xs@sm">
                        <share-btn class="u-marg-l-xs | u-marg-l-0@sm" />
                    </div>
                </div>
            </div>
        </section>

        <section class="js-section js-nav-section c-SectionFive-dataTwo c-Container u-relative u-bg--white u-overflow-h">
            <h2 class="t-h3 u-w6of16 u-offset-l-w6of16 | u-offset-l-w5of16@md | u-w6of8@sm u-offset-l-w1of8@sm">Adaptation of <br class="u-hide@sm">Ai in all business sectors</h2>

            <div class="c-SectionFive-dataTwo-desc u-w7of16 u-offset-l-w2of16 | u-w8of16@md u-offset-l-w1of16@md | u-w14of16@sm u-offset-l-w1of16@sm">
                <p class=" t-h4">AI will be a catalyst to smarter, faster, leaner and greater innovation. 72% of Business Executives agree innovations using AI will be even more important in a post-COVID 19 world.</p>

                <div class="u-marg-t-sm | u-marg-t-md@sm u-relative@sm">
                    <question-btn number="52" question="Thinking about Artificial Intelligence, Automation and Machine Learning, how important do you think innovations using AI, Automation and Machine Learning will be in a “post-COVID-19 world”?" response="(Top 3 Box) [NEW SEPT 2020 BE] Base: Business executives: 1,128" />
                    <share-btn class="u-marg-l-xs" />
                </div>
            </div>

            <div class="u-flex u-flex-flow-row-wrap">
                <div class="c-SectionFive-dataTwo-switch c-Switch u-offset-l-w6of16 u-w3of16 | u-w4of16@lg | | u-w5of16@md u-offset-l-w5of16@md | u-w6of8@sm u-offset-l-w1of8@sm">
                    <p class="t-text--sm">Which industry do you think will benefit the most from AI in the future?</p>
                    <p class="c-Switch-legend t-text--sm u-opacity--50 u-italic u-marg-t-sm"><span class="u-hide@sm">Click on an industry to select it</span><span class="u-hide | u-block@sm">Drag and tap on an industry to select it</span></p>
                </div>

                <div  v-bind:class="{ 'is-active': industryActive }" class="c-SectionFive-dataTwo-legend u-w3of16 u-offset-l-w1of16 | u-w6of8@sm u-offset-l-w1of8@sm">
                    <!-- <span class="t-text--sm"><span class="c-SectionFive-dataTwo-legend-icon u-bg--blue-light"></span>Your answer</span> -->
                    <ul class="t-list">
                        <li class="t-text--sm u-relative"><span class="c-SectionFive-dataTwo-legend-icon u-bg--blue-light"></span>Your Answer</li>
                        <li class="t-text--sm u-relative"><span class="c-SectionFive-dataTwo-legend-icon u-bg--blue"></span><span class="c-SectionFive-dataTwo-legend-icon u-bg--gray"></span>Business Executives' answers</li>
                    </ul>
                </div>
            </div>

            <default-slider v-bind:init-offset="0.5" class="u-select-none u-relative u-fit-w">
                <div v-bind:class="{ 'is-active': industryActive }" class="js-item c-SectionFive-dataTwo-industry u-relative u-w14of16 u-offset-l-w1of16 | u-fit-w@lg u-marg-l-0@lg">
                    <button v-on:click="onIndustryClick(0)" v-bind:class="{ 'is-current': currentIndustry === 0 }" type="btn" class="c-SectionFive-dataTwo-industry-item c-SectionFive-dataTwo-industry-item--0 t-btn u-flex u-align-items-c u-justify-content-c u-pad-a-md">
                        <div class="c-SectionFive-dataTwo-industry-item-bg u-absolute u-pos-tl u-fit t-b-radius-md"></div>
                        <div class="u-relative u-text-center">
                            <span class="c-SectionFive-dataTwo-industry-item-number t-h4 u-block">70<sup>%</sup></span>
                            <span class="c-SectionFive-dataTwo-industry-item-label u-uppercase u-font-bold">electronics</span>
                        </div>
                    </button>
                    <button v-on:click="onIndustryClick(1)" v-bind:class="{ 'is-current': currentIndustry === 1 }" type="btn" class="c-SectionFive-dataTwo-industry-item c-SectionFive-dataTwo-industry-item--1 t-btn u-flex u-align-items-c u-justify-content-c u-pad-a-md">
                        <div class="c-SectionFive-dataTwo-industry-item-bg u-absolute u-pos-tl u-fit t-b-radius-sm"></div>
                        <div class="u-relative u-text-center">
                            <span class="c-SectionFive-dataTwo-industry-item-number t-h4 u-block">65<sup>%</sup></span>
                            <span class="c-SectionFive-dataTwo-industry-item-label u-uppercase u-font-bold">FMCG</span>
                        </div>
                    </button>
                    <button v-on:click="onIndustryClick(2)" v-bind:class="{ 'is-current': currentIndustry === 2 }" type="btn" class="c-SectionFive-dataTwo-industry-item c-SectionFive-dataTwo-industry-item--3 t-btn u-flex u-align-items-c u-justify-content-c u-pad-a-md">
                        <div class="c-SectionFive-dataTwo-industry-item-bg u-absolute u-pos-tl u-fit t-b-radius-sm"></div>
                        <div class="u-relative u-text-center">
                            <span class="c-SectionFive-dataTwo-industry-item-number t-h4 u-block">69<sup>%</sup></span>
                            <span class="c-SectionFive-dataTwo-industry-item-label u-uppercase u-font-bold">Professional services / Business services</span>
                        </div>
                    </button>
                    <button v-on:click="onIndustryClick(3)" v-bind:class="{ 'is-current': currentIndustry === 3 }" type="btn" class="c-SectionFive-dataTwo-industry-item c-SectionFive-dataTwo-industry-item--4 t-btn u-flex u-align-items-c u-justify-content-c u-pad-a-md">
                        <div class="c-SectionFive-dataTwo-industry-item-bg u-absolute u-pos-tl t-b-radius-md u-fit">
                            <div class="c-SectionFive-dataTwo-industry-item-bg-inner u-absolute u-pos-tl u-fit"></div>
                        </div>
                        <div class="u-relative u-text-center">
                            <span class="c-SectionFive-dataTwo-industry-item-number t-h2 u-block">75<sup>%</sup></span>
                            <span class="c-SectionFive-dataTwo-industry-item-label u-uppercase u-font-bold">health related</span>
                        </div>
                    </button>
                    <button v-on:click="onIndustryClick(4)" v-bind:class="{ 'is-current': currentIndustry === 4 }" type="btn" class="c-SectionFive-dataTwo-industry-item c-SectionFive-dataTwo-industry-item--5 t-btn u-flex u-align-items-c u-justify-content-c u-pad-a-md">
                        <div class="c-SectionFive-dataTwo-industry-item-bg u-absolute u-pos-tl t-b-radius-md u-fit">
                            <div class="c-SectionFive-dataTwo-industry-item-bg-inner u-absolute u-pos-tl u-fit"></div>
                        </div>
                        <div class="u-relative u-text-center">
                            <span class="c-SectionFive-dataTwo-industry-item-number t-h2 u-block">76<sup>%</sup></span>
                            <span class="c-SectionFive-dataTwo-industry-item-label u-uppercase u-font-bold">high tech / it</span>
                        </div>
                    </button>
                    <button v-on:click="onIndustryClick(5)" v-bind:class="{ 'is-current': currentIndustry === 5 }" type="btn" class="c-SectionFive-dataTwo-industry-item c-SectionFive-dataTwo-industry-item--6 t-btn u-flex u-align-items-c u-justify-content-c u-pad-a-md">
                        <div class="c-SectionFive-dataTwo-industry-item-bg u-absolute u-pos-tl t-b-radius-md u-fit">
                            <div class="c-SectionFive-dataTwo-industry-item-bg-inner u-absolute u-pos-tl u-fit"></div>
                        </div>
                        <div class="u-relative u-text-center">
                            <span class="c-SectionFive-dataTwo-industry-item-number t-h2 u-block">73<sup>%</sup></span>
                            <span class="c-SectionFive-dataTwo-industry-item-label u-uppercase u-font-bold">other manufacturing</span>
                        </div>
                    </button>
                    <button v-on:click="onIndustryClick(6)" v-bind:class="{ 'is-current': currentIndustry === 6 }" type="btn" class="c-SectionFive-dataTwo-industry-item c-SectionFive-dataTwo-industry-item--7 t-btn u-flex u-align-items-c u-justify-content-c u-pad-a-md">
                        <div class="c-SectionFive-dataTwo-industry-item-bg u-absolute u-pos-tl u-fit t-b-radius-md"></div>
                        <div class="u-relative u-text-center">
                            <span class="c-SectionFive-dataTwo-industry-item-number t-h4 u-block">72<sup>%</sup></span>
                            <span class="c-SectionFive-dataTwo-industry-item-label u-uppercase u-font-bold">industrial products</span>
                        </div>
                    </button>
                    <button v-on:click="onIndustryClick(7)" v-bind:class="{ 'is-current': currentIndustry === 7 }" type="btn" class="c-SectionFive-dataTwo-industry-item c-SectionFive-dataTwo-industry-item--8 t-btn u-flex u-align-items-c u-justify-content-c u-pad-a-md">
                        <div class="c-SectionFive-dataTwo-industry-item-bg u-absolute u-pos-tl u-fit t-b-radius-sm"></div>
                        <div class="u-relative u-text-center">
                            <span class="c-SectionFive-dataTwo-industry-item-number t-h4 u-block">65<sup>%</sup></span>
                            <span class="c-SectionFive-dataTwo-industry-item-label u-uppercase u-font-bold">Telecoms / Internet</span>
                        </div>
                    </button>
                    <button v-on:click="onIndustryClick(8)" v-bind:class="{ 'is-current': currentIndustry === 8 }" type="btn" class="c-SectionFive-dataTwo-industry-item c-SectionFive-dataTwo-industry-item--9 t-btn u-flex u-align-items-c u-justify-content-c u-pad-a-md">
                        <div class="c-SectionFive-dataTwo-industry-item-bg u-absolute u-pos-tl u-fit t-b-radius-md"></div>
                        <div class="u-relative u-text-center">
                            <span class="c-SectionFive-dataTwo-industry-item-number t-h4 u-block">71<sup>%</sup></span>
                            <span class="c-SectionFive-dataTwo-industry-item-label u-uppercase u-font-bold">energy related</span>
                        </div>
                    </button>
                    <button v-on:click="onIndustryClick(9)" v-bind:class="{ 'is-current': currentIndustry === 9 }" type="btn" class="c-SectionFive-dataTwo-industry-item c-SectionFive-dataTwo-industry-item--10 t-btn u-flex u-align-items-c u-justify-content-c u-pad-a-md">
                        <div class="c-SectionFive-dataTwo-industry-item-bg u-absolute u-pos-tl u-fit t-b-radius-sm"></div>
                        <div class="u-relative u-text-center">
                            <span class="c-SectionFive-dataTwo-industry-item-number t-h4 u-block">66<sup>%</sup></span>
                            <span class="c-SectionFive-dataTwo-industry-item-label u-uppercase u-font-bold">Automotive / Transport / Logistics</span>
                        </div>
                    </button>
                </div>
            </default-slider>

            <div class="u-offset-l-w6of16 u-w7of16 | u-offset-l-w5of16@md u-w8of16@md | u-w14of16@sm u-offset-l-w1of16@sm">
                <div class="u-flex u-flex-flow-row-wrap | u-fit@sm u-relative@sm">
                    <question-btn class="u-marg-r-xs" number="21" question="Out of the below statements, which one most aligns to your company’s adoption and understanding of Artificial Intelligence, Automation and Machine Learning?" response="Base: Business Executives 2020: 2,307)" />
                    <question-btn number="22" question="Out of the below statements, which one most aligns to your company’s adoption and understanding of Artificial Intelligence, Automation and Machine Learning?" response="" />

                    <div class="u-flex@sm u-relative@sm u-fit-w@sm u-marg-t-xs@sm">
                        <share-btn class="u-marg-l-xs | u-marg-l-0@sm" />
                    </div>
                </div>

                <div class="c-SectionFive-dataTwo-desc c-SectionFive-dataTwo-desc--alt">
                    <p class="t-h4">Business Executives’ wishes for businesses, rather than governments, to lead innovation are seemingly being fulfilled. 81% say AI and machine learning will be important because of their benefits to the working experience.</p>

                    <div class="u-marg-t-sm | u-marg-t-md@sm u-flex u-flex-flow-row-wrap | u-relative@sm">
                        <question-btn number="7" question="How much do you agree or disagree with the following statements? In my country..." response="(NET Agree) [TRACKED | NEW STATEMENT SEPT 2020 BE] Base: Business executives Sept 2020: 1,128" />
                        <question-btn class="u-marg-l-xs" number="53" question="You said that Artificial Intelligence, Automation and Machine Learning will be important in a “post-COVID-19 world”. From the following reasons why do you think that?" response="[NEW SEPT 2020 BE] Base: Business executives who said AI and Machine Learning will be important in a “post-COVID-19 world”: 1,066" />

                        <div class="u-flex@sm u-relative@sm u-fit-w@sm u-marg-t-xs@sm">
                            <share-btn class="u-marg-l-xs | u-marg-l-0@sm" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="js-section js-nav-section c-SectionFive-dataThree c-Container u-relative u-bg--white ">
            <h2 class="t-h3 u-w6of16 u-offset-l-w6of16 | u-offset-l-w5of16@md | u-w6of8@sm u-offset-l-w1of8@sm">Customer centricity is key</h2>

            <div class="c-SectionFive-dataThree-desc u-w7of16 u-offset-l-w2of16 | u-w8of16@md u-offset-l-w1of16@md | u-w14of16@sm u-offset-l-w1of16@sm">
                <p class=" t-h4">AI will be a catalyst to smarter, faster, leaner and greater innovation. 69% of Business Executives agree that AI creates jobs in their industries.</p>
            </div>


            <div class="u-relative u-flex u-flex-flow-row-wrap">
                <div class="c-SectionFive-dataThree-medias u-w7of16 u-offset-l-w2of16 | u-offset-l-w1of16@md | u-relative@sm u-fit-w@sm u-marg-l-0@sm">
                    <div class="c-SectionFive-dataThree-video">
                        <video-observer class="c-SectionFive-dataThree-video-container t-b-radius-lg">
                            <video-responsive>
                                <video class="js-video js-object-fit  u-absolute u-pos-tl u-fit" :poster="`${publicPath}static/images/five/video-poster.jpg`" preload="auto" loop muted playsinline>
                                    <source :src="`${publicPath}static/videos/five/video.webm`" type="video/webm">
                                    <source :src="`${publicPath}static/videos/five/video.mp4`" type="video/mp4">

                                    <source :srcset="`${publicPath}static/videos/five/video-mobile.webm`" type="video/webm" media="(max-width: 767px)">
                                    <source :srcset="`${publicPath}static/videos/five/video-mobile.mp4`" type="video/mp4" media="(max-width: 767px)">
                                </video>
                            </video-responsive>
                        </video-observer>
                    </div>

                    <parallax-object v-bind:ratio="0.1" class="c-SectionFive-dataThree-img u-w5of16 u-offset-l-w1of16 u-absolute u-pos-bl | u-offset-l-w0of16@md | u-w13of16@sm u-offset-l-w1of8@sm">
                        <div class="c-SectionFive-dataThree-img-inner t-b-radius-md">
                            <img :src="`${publicPath}static/images/five/2.jpg`" alt="" class="u-absolute u-pos-tl u-fit">
                        </div>
                    </parallax-object>
                </div>

                <div class="u-w4of16 u-offset-l-w1of16 | u-w6of16@md | u-fit-w@sm u-marg-l-0@sm">
                    <div class="u-w3of4 | u-w4of4@lg | u-11of16@sm u-offset-l-w1of16@sm">
                        <p class="t-text--sm">AI is one of the main drivers of the transition towards individualized and customized innovation. Below is what Business Executives think is driving this move.</p>
                        <p class="c-Switch-legend t-text--sm u-opacity-50 u-italic u-marg-t-sm">Interact with the graph to see the data</p>
                    </div>

                    <appear-object v-bind:active="isPageReady" class="c-SectionFive-dataThree-chart u-w3of4 | u-w4of4@lg | u-w6of8@sm u-offset-l-w1of8@sm">
                        <div v-on:mouseenter="currentChart = 0" v-on:touchend="currentChart = 0" v-bind:class="{ 'is-active': currentChart === 0 }" class="c-SectionFive-dataThree-chart-item c-SectionFive-dataThree-chart-item--0">
                            <div class="c-SectionFive-dataThree-chart-item-cyan"></div>
                            <div class="c-SectionFive-dataThree-chart-item-blue"></div>
                        </div>
                        <div v-on:mouseenter="currentChart = 1" v-on:touchend="currentChart = 1" v-bind:class="{ 'is-active': currentChart === 1 }" class="c-SectionFive-dataThree-chart-item c-SectionFive-dataThree-chart-item--1">
                            <div class="c-SectionFive-dataThree-chart-item-cyan"></div>
                            <div class="c-SectionFive-dataThree-chart-item-blue"></div>
                        </div>
                        <div v-on:mouseenter="currentChart = 2" v-on:touchend="currentChart = 2" v-bind:class="{ 'is-active': currentChart === 2 }" class="c-SectionFive-dataThree-chart-item c-SectionFive-dataThree-chart-item--2">
                            <div class="c-SectionFive-dataThree-chart-item-cyan"></div>
                            <div class="c-SectionFive-dataThree-chart-item-blue"></div>
                        </div>
                        <div v-on:mouseenter="currentChart = 3" v-on:touchend="currentChart = 3" v-bind:class="{ 'is-active': currentChart === 3 }" class="c-SectionFive-dataThree-chart-item c-SectionFive-dataThree-chart-item--3">
                            <div class="c-SectionFive-dataThree-chart-item-cyan"></div>
                            <div class="c-SectionFive-dataThree-chart-item-blue"></div>
                        </div>
                        <div v-on:mouseenter="currentChart = 4" v-on:touchend="currentChart = 4" v-bind:class="{ 'is-active': currentChart === 4 }" class="c-SectionFive-dataThree-chart-item c-SectionFive-dataThree-chart-item--4">
                            <div class="c-SectionFive-dataThree-chart-item-cyan"></div>
                            <div class="c-SectionFive-dataThree-chart-item-blue"></div>
                        </div>
                        <div v-on:mouseenter="currentChart = 5" v-on:touchend="currentChart = 5" v-bind:class="{ 'is-active': currentChart === 5 }" class="c-SectionFive-dataThree-chart-item c-SectionFive-dataThree-chart-item--5">
                            <div class="c-SectionFive-dataThree-chart-item-cyan"></div>
                            <div class="c-SectionFive-dataThree-chart-item-blue"></div>
                        </div>
                        <div v-on:mouseenter="currentChart = 6" v-on:touchend="currentChart = 6" v-bind:class="{ 'is-active': currentChart === 6 }" class="c-SectionFive-dataThree-chart-item c-SectionFive-dataThree-chart-item--6">
                            <div class="c-SectionFive-dataThree-chart-item-cyan"></div>
                            <div class="c-SectionFive-dataThree-chart-item-blue"></div>
                        </div>
                    </appear-object>

                    <div class="u-relative u-marg-y-md | u-w6of8@sm u-offset-l-w1of8@sm">
                        <div v-bind:class="{ 'is-active': currentChart === 0 }" class="c-SectionFive-dataThree-chart-legend u-flex u-flex-flow-row-wrap u-align-items-c">
                            <div class="u-w1of4 | u-fit-w@sm u-marg-b-xs@sm"><span class="c-SectionFive-dataThree-number t-h2 u-color--blue">41<sup>%</sup></span></div>
                            <div class="u-w3of4 | u-fit-w@sm">
                                <p class="c-SectionFive-dataThree-label t-text--sm u-color--blue u-uppercase u-font-bold">Increased use of artificial intelligence</p>
                            </div>
                        </div>

                        <div v-bind:class="{ 'is-active': currentChart === 1 }" class="c-SectionFive-dataThree-chart-legend u-flex u-flex-flow-row-wrap u-align-items-c u-absolute u-pos-tl">
                            <div class="u-w1of4 | u-fit-w@sm u-marg-b-xs@sm"><span class="c-SectionFive-dataThree-number t-h2 u-color--blue">39<sup>%</sup></span></div>
                            <div class="u-w3of4 | u-fit-w@sm">
                                <p class="c-SectionFive-dataThree-label t-text--sm u-color--blue u-uppercase u-font-bold">Increased use of artificial intelligence</p>
                            </div>
                        </div>

                        <div v-bind:class="{ 'is-active': currentChart === 2 }" class="c-SectionFive-dataThree-chart-legend u-flex u-flex-flow-row-wrap u-align-items-c u-absolute u-pos-tl">
                            <div class="u-w1of4 | u-fit-w@sm u-marg-b-xs@sm"><span class="c-SectionFive-dataThree-number t-h2 u-color--blue">36<sup>%</sup></span></div>
                            <div class="u-w3of4 | u-fit-w@sm">
                                <p class="c-SectionFive-dataThree-label t-text--sm u-color--blue u-uppercase u-font-bold">Increasing expectation among end-users</p>
                            </div>
                        </div>

                        <div v-bind:class="{ 'is-active': currentChart === 3 }" class="c-SectionFive-dataThree-chart-legend u-flex u-flex-flow-row-wrap u-align-items-c u-absolute u-pos-tl">
                            <div class="u-w1of4 | u-fit-w@sm u-marg-b-xs@sm"><span class="c-SectionFive-dataThree-number t-h2 u-color--blue">36<sup>%</sup></span></div>
                            <div class="u-w3of4 | u-fit-w@sm">
                                <p class="c-SectionFive-dataThree-label t-text--sm u-color--blue u-uppercase u-font-bold">Increasing expectation among end-users</p>
                            </div>
                        </div>

                        <div v-bind:class="{ 'is-active': currentChart === 4 }" class="c-SectionFive-dataThree-chart-legend u-flex u-flex-flow-row-wrap u-align-items-c u-absolute u-pos-tl">
                            <div class="u-w1of4 | u-fit-w@sm u-marg-b-xs@sm"><span class="c-SectionFive-dataThree-number t-h2 u-color--blue">35<sup>%</sup></span></div>
                            <div class="u-w3of4 | u-fit-w@sm">
                                <p class="c-SectionFive-dataThree-label t-text--sm u-color--blue u-uppercase u-font-bold">The time efficiencies that can be created</p>
                            </div>
                        </div>

                        <div v-bind:class="{ 'is-active': currentChart === 5 }" class="c-SectionFive-dataThree-chart-legend u-flex u-flex-flow-row-wrap u-align-items-c u-absolute u-pos-tl">
                            <div class="u-w1of4 | u-fit-w@sm u-marg-b-xs@sm"><span class="c-SectionFive-dataThree-number t-h2 u-color--blue">35<sup>%</sup></span></div>
                            <div class="u-w3of4 | u-fit-w@sm">
                                <p class="c-SectionFive-dataThree-label t-text--sm u-color--blue u-uppercase u-font-bold">The time efficiencies that can be created</p>
                            </div>
                        </div>

                        <div v-bind:class="{ 'is-active': currentChart === 6 }" class="c-SectionFive-dataThree-chart-legend u-flex u-flex-flow-row-wrap u-align-items-c u-absolute u-pos-tl">
                            <div class="u-w1of4 | u-fit-w@sm u-marg-b-xs@sm"><span class="c-SectionFive-dataThree-number t-h2 u-color--blue">34<sup>%</sup></span></div>
                            <div class="u-w3of4 | u-fit-w@sm">
                                <p class="c-SectionFive-dataThree-label t-text--sm u-color--blue u-uppercase u-font-bold">The opportunity to increase repeat purchase</p>
                            </div>
                        </div>
                    </div>

                    <div class="u-w6of8@sm u-offset-l-w1of8@sm u-relative@sm">
                        <question-btn v-bind:right="true" number="37" question="What do you think is driving this move to more individualized and customized innovation?" response="Base: Business Executives who agree that innovations are becoming more customized or that a one size fits all model is becoming redundant 2020: 2,136" />
                        <share-btn v-bind:right="true" class="u-marg-l-xs" />
                    </div>
                </div>
            </div>

        </section>

        <section class="js-section u-fit-w u-bg--gray u-overflow-h" >
            <parallax-object v-bind:ratio="-0.8" class="u-relative u-pos-tl u-fit">
                <a href="/sections" v-on:click.prevent="onDownloadClick" v-bind:class="{ 'is-active': downloadActive }" class="c-SectionFive-download c-Container t-link u-block u-fit u-flex u-align-items-c">
                    <div class="c-SectionFive-download-content u-offset-l-w2of16 u-w10of15 u-flex u-align-items-c | u-block@sm u-w4of8@sm u-offset-l-w1of8@sm">
                        <span class="t-h4 | u-block@sm">Download Reports</span>
                        <svg class="u-marg-l-md | u-marg-l-0@sm u-marg-t-md@sm" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 44" width="40" height="44">
                            <path d="M0 22.8l20.4.1-4.7 4.7 1.1 1 6.5-6.4-6.7-6.7-1.1 1.1 4.9 4.8L0 21.3v1.5z" fill="#0e1d2a"/>
                            <path d="M2.3 8.8C6.1 4.3 11.7 1.5 18 1.5c11.3 0 20.5 9.2 20.5 20.5S29.3 42.5 18 42.5c-6.4 0-12.1-2.9-15.8-7.5L1 36c4 4.9 10.1 8 17 8 12.1 0 22-9.9 22-22S30.1 0 18 0C11.2 0 5.2 3.1 1.2 7.9l1.1.9z" opacity=".15" fill="#0e1d2a"/>
                        </svg>
                    </div>

                    <div class="c-SectionFive-download-hover u-absolute u-pos-tl u-fit u-bg--blue u-color--white">
                        <div class="c-SectionFive-download-hover-inner c-Container u-absolute u-pos-tl u-fit">
                            <div class="c-SectionFive-download-content u-offset-l-w2of16 u-w10of15 u-flex u-align-items-c | u-block@sm u-w4of8@sm u-offset-l-w1of8@sm">
                                <span class="t-h4 | u-block@sm">Download Reports</span>
                                <svg class="u-marg-l-md | u-marg-l-0@sm u-marg-t-md@sm" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 44" width="40" height="44">
                                    <path d="M0 22.8l20.4.1-4.7 4.7 1.1 1 6.5-6.4-6.7-6.7-1.1 1.1 4.9 4.8L0 21.3v1.5z" fill="#ffffff"/>
                                    <path d="M2.3 8.8C6.1 4.3 11.7 1.5 18 1.5c11.3 0 20.5 9.2 20.5 20.5S29.3 42.5 18 42.5c-6.4 0-12.1-2.9-15.8-7.5L1 36c4 4.9 10.1 8 17 8 12.1 0 22-9.9 22-22S30.1 0 18 0C11.2 0 5.2 3.1 1.2 7.9l1.1.9z" opacity=".15" fill="#ffffff"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </a>
            </parallax-object>
        </section>

    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    import { gsap, Quad } from 'gsap';
    import scrollToY from '@/utilities/scrollToY';
    import getOffsetTop from '@/utilities/getOffsetTop';

    import CursorTrigger from '@/components/CursorTrigger';
    import ParallaxObject from '@/components/ParallaxObject';
    import ObjectFit from '@/components/ObjectFit';
    import VideoObserver from '@/components/VideoObserver';
    import AppearObject from '@/components/AppearObject';
    import SubHeader from '@/components/SubHeader';
    import QuestionBtn from '@/components/QuestionBtn';
    import VideoResponsive from '@/components/VideoResponsive';
    import DefaultSlider from '@/components/DefaultSlider';
    import ShareBtn from '@/components/ShareBtn';

    export default {
        name: 'Home',

        props: {
            initIndex: { type: Number, default: 0 },
        },

        components: {
            CursorTrigger,
            ParallaxObject,
            ObjectFit,
            VideoObserver,
            AppearObject,
            SubHeader,
            QuestionBtn,
            VideoResponsive,
            DefaultSlider,
            ShareBtn,
        },

        data() {
            return {
                publicPath: process.env.BASE_URL,
                isPageReady: false,
                isFromSections: false,
                isFromSection: false,

                numberOne: 0,
                numberTwo: 0,

                industryActive: false,
                currentIndustry: -1,

                currentChart: 0,

                downloadActive: false,
            };
        },

        computed: {
            ...mapState('window', [
                'minHeight',
            ]),

            ...mapGetters('window', [
                'halfHeight',
                'isMobile',
            ]),
        },

        beforeRouteEnter (to, from, next) {
            next((vm) => {
                if(from.meta.type === 'section') {
                    vm.isFromSection = true;
                }

                if (from.name === 'Sections') {
                    vm.isFromSections = true;
                }
            });
        },

        created() {
            this.observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.onNumberAppaer(entry.target);
                        this.observer.unobserve(entry.target);
                    }
                });
            });
        },

        mounted() {
            this.setCurrentNav(50);
            this.$eventHub.$on('page:ready', this.onPageReady);

            this.$eventHub.$on('resize', this.resize);
            this.$eventHub.$on('update', this.update);
            this.$eventHub.$on('route:scroll', this.onRouteScroll);

            this.$nextTick(() => {
                this.$eventHub.$emit('page:enter');

                this.$navSections = Array.from(this.$el.querySelectorAll('.js-nav-section'));
            });

            this.observer.observe(this.$refs.numberOne);
            this.observer.observe(this.$refs.numberTwo);
        },

        beforeDestroy() {
            this.$eventHub.$off('page:ready', this.onPageReady);

            this.$eventHub.$off('resize', this.resize);
            this.$eventHub.$off('update', this.update);
            this.$eventHub.$off('route:scroll', this.onRouteScroll);

            this.observer.unobserve(this.$refs.numberOne);
            this.observer.unobserve(this.$refs.numberTwo);
        },

        methods: {
            ...mapActions('main', [
                'setCurrentNav'
            ]),

            resize() {
                if (!this.$navSections) return;

                this.cuePoints = [];

                this.$navSections.forEach((el) => {
                    this.cuePoints.push(getOffsetTop(el));
                });
            },

            update() {
                if (!this.cuePoints) return;

                this.cuePoints.forEach((offset, i) => {
                    let nextCuePoints = this.cuePoints[i+1] ? this.cuePoints[i+1] : 99999999;

                    if (this.$root.smoothScroll > this.cuePoints[i] - this.halfHeight && this.$root.smoothScroll < nextCuePoints - this.halfHeight ) {
                        this.setCurrentNav(50+i);
                    }
                });
            },

            onScroll() {
                scrollToY(this.$root.scrollTop + this.minHeight);
            },

            onPageReady() {
                this.isPageReady = true;

                if (this.initIndex) {
                    this.cuePoints[this.initIndex];

                    const offset = this.initIndex < 2 ? 0 : 160;
                    scrollToY(this.cuePoints[this.initIndex] - offset);
                }

                setTimeout(() => {
                    this.$eventHub.$emit('webgl:pause');
                }, 800);
            },

            onRouteScroll(index) {
                if (!this.isPageReady) return;

                const offset = index < 2 ? 0 : 160;
                scrollToY(this.cuePoints[index] - offset);
            },

            onNumberAppaer(elem) {
                let init  = { value: 0 };
                // let target = (elem === this.$refs.numberOne) ? 65 : 75;
                let target = 20;

                gsap.to(init, 0.8, { value: target, ease: Quad.easeOut, onUpdate: () => {
                    if (elem === this.$refs.numberOne) {
                        this.numberOne = Math.round(init.value);
                    } else {
                        this.numberTwo = Math.round(init.value);
                    }
                } });
            },

            onDownloadClick() {
                this.downloadActive = true;

                clearTimeout(this.timerDownloadClick);
                this.timerDownloadClick = setTimeout(() => {
                    this.$router.push({ name: 'Sections', params: { initIndex: 5 } });
                }, 200);
            },

            onIndustryClick(index) {
                if (!this.industryActive) {
                    this.industryActive = true;
                    this.currentIndustry = index;
                }
            }
        }
    }
</script>

<style lang="stylus">
    .c-SectionFive {
        &-line {
            opacity 0
            transform translateY(80px) translateZ(0)
            will-change transform, opacity

            .is-page-ready & {
                opacity 1
                transform translateZ(0)
            }

            for n in 0..4 {
                &--{n} {
                     transition transform 0.8s $ease-out-quart (n * 0.1s), opacity 0.8s $ease-out-quart (n * 0.1s)
                }
            }

            .is-from-sections & {
                opacity 1
                transform translateZ(0)
                transition none;
            }
        }

        &-intro {
            z-index 0

            &-video {
                opacity 0
                transform translateZ(0)
                transition opacity 0.8s $ease-out-quart
                will-change opacity

                .is-page-ready & {
                    opacity 1
                }

                .is-from-section & {
                    opacity 1
                    transition none
                }
            }

            &-title {
                &-line {
                    &-2 {
                        margin-top 140px

                        @media $breakpoints.lg {
                            margin-top calc(140 * 100vh / 1125)
                        }

                        @media $breakpoints.sm {
                            margin-top calc(80 * 100vh / 1125)
                        }
                    }

                    &-3 {
                        margin-top 5px

                        @media $breakpoints.sm {
                            margin-top 0
                        }
                    }
                }
            }

            &-desc {
                margin-top 30px

                @media $breakpoints.sm {
                    margin-top 75px
                    margin-bottom 95px
                }

                @media $breakpoints.smh {
                    margin-top 40px
                }
            }

            &-icon {
                margin-top -4px
                margin-right 12px
            }

            &-text {
                margin-top 20px
            }

            &-scroll {
                margin-bottom 90px

                @media $breakpoints.sm {
                    margin-bottom 42px
                }

                &-btn {
                    opacity 0
                    transform translateZ(0)
                    transition opacity 0.8s $ease-out-quart
                    will-change opacity

                    .is-page-ready & {
                        opacity 1
                    }
                }
            }
        }

        &-dataOne {
            z-index 1
            padding-top 355px
            padding-bottom 315px

            @media $breakpoints.sm {
                padding-top 270px
                padding-bottom 270px
            }

            &-desc {
                padding-top 145px
                padding-bottom 145px

                @media $breakpoints.sm {
                    padding-bottom 125px
                }

                &--alt {
                    padding-top 115px
                    padding-bottom 0

                    @media $breakpoints.sm {
                        padding-top 145px
                    }
                }
            }

            &-number {
                line-height 1
                font-size 11.528vw

                @media $breakpoints.sm {
                    font-size 40.8vw
                }

                sup {
                    top -0.5em
                    font-size 60%
                }

                &-item {
                    opacity 0
                    will-change opacity
                    transform translateZ(0)

                    for n in (0..20) {
                        &:nth-child({n}) {
                            .is-number-{n} & {
                                opacity 1
                            }
                        }
                    }
                }
            }

            &-head {
                padding-bottom 90px
            }

            &-video {
                padding-right 2.78%

                @media $breakpoints.sm {
                    padding-top 140px
                    padding-right 0
                }

                &-container {
                    aspect-ratio(16, 9)
                    overflow hidden
                    // background $colors['black'];

                    @media $breakpoints.sm {
                        aspect-ratio(374, 664)
                    }
                }
            }

            &-img {
                bottom 75px
                padding-right 2.083%

                @media $breakpoints.sm {
                    top 25px
                    bottom auto
                    padding-right 0
                }

                &-inner {
                    aspect-ratio(394, 254)
                    overflow hidden
                }
            }

            &-gradient {
                top 70px
                // padding-right 3.472%
                width 9.028%
                backdrop-filter: blur(7px);

                @media $breakpoints.lg {
                    top 30px
                }


                @media $breakpoints.sm {
                    top 0
                    padding-right 0
                    width 25%
                }

                &-inner {
                    aspect-ratio(120, 150)
                    overflow hidden

                    background linear-gradient(to top, #0060b4, rgba(#0eb5df, 0.1));
                }
            }

            &-foot {
                padding-top 115px

                @media $breakpoints.sm {
                    padding-top 95px
                }
            }
        }


        &-dataTwo {
            z-index 1
            padding-bottom 310px

            @media $breakpoints.sm {
                padding-bottom 270px
            }

            &-desc {
                padding-top 145px
                padding-bottom 115px

                &--alt {
                    padding-top 155px
                    padding-bottom 0
                }

                &-buttons {
                    margin-top 25px

                    @media $breakpoints.sm {
                        margin-top 65px
                    }
                }
            }

            &-legend {
                opacity 0
                transform translateZ(0)
                will-change opacity
                transition opacity 0.6s $ease-out-quart

                &.is-active {
                    opacity 1
                }

                &-icon {
                    position absolute
                    top 6px
                    left -18px
                    width 10px
                    height 8px
                    border-radius 2px
                    display inline-block
                    vertical-align middle
                }

                &-icon + &-icon {
                    left -32px
                }
            }

            &-industry {
                height 600px
                margin-top 50px
                margin-bottom 90px

                @media $breakpoints.md {
                    width 170%
                }

                @media $breakpoints.sm {
                    width 300%
                }

                &-item {
                    will-change transform

                    position absolute

                    color $colors['black'];

                    .is-active & {
                        transform translateZ(0);

                        .c-SectionFive-dataTwo-industry-item-bg {
                            transform translateZ(0);
                        }
                    }

                    &-bg {
                        overflow hidden
                        will-change transform
                        transition transform 0.8s $ease-out-quart

                        &:before, &:after {
                            content ""
                            position absolute
                            top 0
                            left 0
                            width 100%
                            height 100%
                            background #f1f2f4;
                            // border-radius 4px
                        }

                        &:after {
                            background $colors['blue-light'];

                            opacity 0
                            transform translateZ(0)
                            will-change opacity
                            transition opacity 0.6s $ease-out-quart


                        }

                        &-inner {
                            background $colors['blue'];
                            // border-radius 4px

                            opacity 0
                            transform translateZ(0)
                            will-change opacity
                            transition opacity 0.6s $ease-out-quart

                            .is-active & {
                                opacity 1
                            }
                        }
                    }

                    &:hover {
                        .c-SectionFive-dataTwo-industry-item-bg {
                            &:after {
                                opacity 1

                                .is-active & {
                                    opacity 0
                                }
                            }
                        }

                        color $colors['white'];

                        .is-active & {
                            color $colors["black"];
                        }

                        .c-SectionFive-dataTwo-industry-item-label {
                            opacity 1
                        }
                    }

                    &.is-current {
                        .c-SectionFive-dataTwo-industry-item-bg {
                            &:after {
                                opacity 1
                            }
                        }

                        color $colors['white'];

                        &:hover {
                            .c-SectionFive-dataTwo-industry-item-bg {
                                &:after {
                                    opacity 1
                                }
                            }

                            color $colors['white'];
                        }
                    }



                    &-number {
                        position absolute
                        top 50%
                        left 50%

                        opacity 0
                        transform translate(-50%, -50%) translateZ(0)
                        will-change opacity, transform
                        transition transform 0.8s $ease-out-quart, opacity 0.8s $ease-out-quart 0.2s

                        .is-active & {
                            opacity 0.5
                            transform translate(-50%, -10px) translateY(-17px)  translateZ(0)
                        }

                        sup {
                            top -0.5em
                            font-size 60%
                        }

                        .is-current & {
                            opacity 1 !important
                        }
                    }

                    &-label {
                        // height 14px
                        display block
                        line-height 1.17
                        letter-spacing 0.05em
                        font-size 12px
                        opacity 0.5

                        transform translateZ(0);
                        will-change transform
                        transition transform 0.8s $ease-out-quart

                        .is-active & {
                            transform translateY(17px)  translateZ(0);
                            opacity 0.5
                        }

                        .is-current & {
                            opacity 1 !important
                        }

                        .is-active:hover & {
                            opacity 0.5
                        }

                        // .is-current:hover & {
                        //     opacity 1
                        // }
                    }

                    &--0 {
                        top calc(20 * 100% / 600)
                        height calc(172 * 100% / 600)

                        width calc(268 * 100% / 1192)
                        left calc(122 * 100% / 1192)

                        transform-origin bottom right
                        // transform translateX(15%) translateY(-22%) scale(0.649) translateZ(0); // (40*100/1192)*(100/22)  (22 = width)
                        transform translateX(32.5%) translateY(-5%) translateZ(0);
                        transition transform 0.8s $ease-out-quart

                        .c-SectionFive-dataTwo-industry-item-bg {
                            transform scale(0.649) translateZ(0);
                        }
                    }

                    &--1 {
                        top calc(0 * 100% / 600)
                        height calc(128 * 100% / 600)

                        width calc(202 * 100% / 1192)
                        left calc(396 * 100% / 1192)


                        transform-origin bottom right
                        // transform translateX(6.13%) translateY(20%) scale(0.861) translateZ(0) // (14*100/1192)*(100/16.946)
                        transform translateX(13%) translateY(26.5%) translateZ(0)
                        will-change transform
                        transition transform 0.8s $ease-out-quart

                        .c-SectionFive-dataTwo-industry-item-bg {
                            transform scale(0.861) translateZ(0);
                        }
                    }

                    &--3 {
                        top calc(58 * 100% / 600)
                        height calc(162 * 100% / 600)

                        width calc(254 * 100% / 1192)
                        left calc(842 * 100% / 1192)

                        transform-origin bottom left
                        // transform translateX(-16%) translateY(4%) scale(1.118) translateZ(0) // (40*100/1192)*(100/21.308)
                        transform translateX(-10%) translateY(-2%) translateZ(0)
                        will-change transform
                        transition transform 0.8s $ease-out-quart

                        .c-SectionFive-dataTwo-industry-item-bg {
                            transform scale(1.118) translateZ(0);
                        }

                        .c-SectionFive-dataTwo-industry-item-number {
                            .is-active & {
                                opacity 0.5
                                transform translate(-50%, -10px) translateY(-24px)  translateZ(0)
                            }
                        }

                        .c-SectionFive-dataTwo-industry-item-label {
                            .is-active & {
                                transform translateY(17px) translateZ(0)
                            }
                        }

                    }

                    &--4 {
                        top calc(198 * 100% / 600)
                        height calc(220 * 100% / 600)

                        width calc(342 * 100% / 1192)
                        left calc(48 * 100% / 1192)

                        transform-origin center right
                        // transform translateX(11.74%) translateY(-21%) scale(0.921) translateZ(0) // (40*100/1192)*(100/21.308)
                        transform translateX(15.5%) translateY(-21%) translateZ(0)
                        will-change transform
                        transition transform 0.8s $ease-out-quart

                        .c-SectionFive-dataTwo-industry-item-bg {
                            transform scale(0.921) translateZ(0);
                        }

                        .is-active & {
                            color $colors['white'];

                            .c-SectionFive-dataTwo-industry-item-number {
                                transform translate(-50%, -10px) translateY(-22px) translateZ(0)
                                opacity 1
                            }

                            .c-SectionFive-dataTwo-industry-item-label {
                                transform translateY(22px) translateZ(0)
                                opacity 1
                            }

                            &:hover {
                                color $colors['white'];
                            }
                        }
                    }

                    &--5 {
                        top calc(134 * 100% / 600)
                        height calc(284 * 100% / 600)

                        width calc(440 * 100% / 1192)
                        left calc(396 * 100% / 1192)

                        transform-origin center
                        // transform translateX(0) scale(0.818) translateZ(0) // (40*100/1192)*(100/36.912)
                        transform translateX(0) translateZ(0)
                        will-change transform
                        transition transform 0.8s $ease-out-quart

                        .c-SectionFive-dataTwo-industry-item-bg {
                            transform scale(0.818) translateZ(0);
                        }

                        .is-active & {
                            color $colors['white'];

                            .c-SectionFive-dataTwo-industry-item-number {
                                transform translate(-50%, -10px) translateY(-22px) translateZ(0)
                                opacity 1
                            }

                            .c-SectionFive-dataTwo-industry-item-label {
                                transform translateY(22px) translateZ(0)
                                opacity 1
                            }

                            &:hover {
                                color $colors['white'];
                            }
                        }
                    }

                    &--6 {
                        top calc(226 * 100% / 600)
                        height calc(192 * 100% / 600)

                        width calc(300 * 100% / 1192)
                        left calc(842 * 100% / 1192)

                        transform-origin center left
                        // transform translateX(-13.5%) translateY(-5%) scale(0.83) translateZ(0)// (40*100/1192)*(100/36.912)
                        transform translateX(-22%) translateY(-5%) translateZ(0)
                        will-change transform
                        transition transform 0.8s $ease-out-quart

                        .c-SectionFive-dataTwo-industry-item-bg {
                            transform scale(0.83) translateZ(0);
                        }

                        .is-active & {
                            color $colors['white'];

                            .c-SectionFive-dataTwo-industry-item-number {
                                transform translate(-50%, -10px) translateY(-22px) translateZ(0)
                                opacity 1
                            }

                            .c-SectionFive-dataTwo-industry-item-label {
                                transform translateY(22px) translateZ(0)
                                opacity 1
                            }

                            &:hover {
                                color $colors['white'];
                            }
                        }

                    }

                    &--7 {
                        top calc(424 * 100% / 600)
                        height calc(182 * 100% / 600)

                        width calc(286 * 100% / 1192)
                        left calc(58 * 100% / 1192)

                        // transform translateX(29.7%) translateY(-30%) scale(0.83) translateZ(0) // (40*100/1192)*(100/36.912)
                        transform-origin top right
                        transform translateX(38%) translateY(-38%) translateZ(0)
                        will-change transform
                        transition transform 0.8s $ease-out-quart

                        .c-SectionFive-dataTwo-industry-item-bg {
                            transform scale(0.83) translateZ(0);
                        }
                    }

                    &--8 {
                        top calc(424 * 100% / 600)
                        height calc(128 * 100% / 600)

                        width calc(202 * 100% / 1192)
                        left calc(350 * 100% / 1192)

                        transform-origin top center
                        // transform translateX(51.3%) translateY(-20%) scale(1.173) translateZ(0) // (40*100/1192)*(100/36.912)
                        transform translateX(51.5%) translateY(-11%) translateZ(0)
                        will-change transform
                        transition transform 0.8s $ease-out-quart

                        .c-SectionFive-dataTwo-industry-item-bg {
                            transform scale(1.173) translateZ(0);
                        }
                    }

                    &--9 {
                        top calc(424 * 100% / 600)
                        height calc(178 * 100% / 600)

                        width calc(278 * 100% / 1192)
                        left calc(558 * 100% / 1192)

                        transform-origin top center
                        // transform translateX(25%) translateY(-14%) scale(0.625) translateZ(0) // (40*100/1192)*(100/36.912)
                        transform translateX(25%) translateY(-33%) translateZ(0)
                        will-change transform
                        transition transform 0.8s $ease-out-quart

                        .c-SectionFive-dataTwo-industry-item-bg {
                            transform scale(0.625) translateZ(0);
                        }
                    }

                    &--10 {
                        top calc(424 * 100% / 600)
                        height calc(150 * 100% / 600)

                        width calc(232 * 100% / 1192)
                        left calc(842 * 100% / 1192)

                        transform-origin top left
                        // transform translateX(7.5%) translateY(-17%) scale(1.021) translateZ(0) // (40*100/1192)*(100/36.912)
                        transform translateX(8.5%) translateY(-16%) translateZ(0)
                        will-change transform
                        transition transform 0.8s $ease-out-quart

                        .c-SectionFive-dataTwo-industry-item-bg {
                            transform scale(1.021) translateZ(0);
                        }

                        .c-SectionFive-dataTwo-industry-item-number {
                            .is-active & {
                                opacity 0.5
                                transform translate(-50%, -10px) translateY(-24px)  translateZ(0)
                            }
                        }

                        .c-SectionFive-dataTwo-industry-item-label {
                            .is-active & {
                                transform translateY(17px) translateZ(0)
                            }
                        }
                    }

                }
            }

       }

       &-dataThree {
            z-index 1
            padding-bottom 320px

            @media $breakpoints.sm {
                padding-bottom 210px
            }

            &-desc {
                padding-top 145px
                padding-bottom 150px

                @media $breakpoints.sm {
                    padding-top 110px
                }
            }

            &-medias {
                @media $breakpoints.sm {
                    margin-bottom 75px
                }
            }

            &-video {
                padding-left 2.083%

                @media $breakpoints.sm {
                    padding-left 0
                    padding-bottom 50px
                }

                &-container {
                    aspect-ratio(564, 710)
                    overflow hidden
                    // background $colors['black'];

                    @media $breakpoints.sm {
                        aspect-ratio(374, 664)
                    }
                }
            }

            &-img {
                padding-right 2.083%
                bottom 65px

                @media $breakpoints.md {
                    top 50%
                    bottom auto
                }

                @media $breakpoints.sm {
                    top auto
                    padding-right 0
                    bottom 0
                }

                &-inner {
                    aspect-ratio(394, 254)
                    overflow hidden

                }
            }

            &-chart {
                position relative
                height 290px
                margin-top 75px

                // @media $breakpoints.sm {
                //     margin-top 125px
                //     margin-bottom 80px
                // }

                &-item {
                    position absolute
                    top 0
                    left 0

                    height 208px;
                    width 35px

                    opacity 0
                    will-change transform, opacity
                    transform scaleY(0) translateZ(0)
                    transform-origin bottom
                    transition transform 0.8s $ease-out-quart, opacity 0.8s $ease-out-quart

                    .is-appear & {
                        opacity 1
                        transform scaleY(1) translateZ(0)
                    }

                    for n in (0..10) {
                        &:nth-child({n}) {
                            transition transform 0.8s $ease-out-quart (n * 0.05s), opacity 0.8s $ease-out-quart (n * 0.05s)
                        }
                    }


                    &--0 {
                        // left 15px
                        left calc(12 * 100% / 255)
                    }

                    &--1 {
                        // left 100px
                        left calc(104 * 100% / 255)
                    }

                    &--2 {
                        top 10px
                        // left 155px
                        left calc(156 * 100% / 255)

                    }

                    &--3 {
                       top 80px
                    }

                    &--4 {
                        top 35px
                        // left 55px
                        left calc(52 * 100% / 255)
                    }

                    &--5 {
                        top 80px
                        // left 135px
                        left calc(132 * 100% / 255)

                    }

                    &--6 {
                        top 45px
                        // left 210px
                        left calc(208 * 100% / 255)

                    }

                    &-cyan {
                        position absolute
                        top 0
                        left 0

                        height 100%;
                        width: 100%;

                        background: linear-gradient(to bottom, #0fa6d7, #FFFFFF)
                        bottom: 0;
                        margin-left: 12px;
                        transform-origin: 0 0;
                        transform: skewY(-20deg);

                        // bar top side
                        &:before {
                            content ""
                            position: absolute;
                            bottom: 100%;
                            left: 0;

                            background-color #23c4e8

                            transform-origin: 0 100%;
                            transform: skewX(50deg);
                            width: 100%;
                            height 10px
                        }

                        // bar right side
                        &:after {
                            content: '';
                            background: linear-gradient(to bottom, #2daeda, #FFFFFF)
                            bottom: 10px;
                            height: 100%;
                            left: -12px;
                            position: absolute;
                            width: 12px;
                            transform-origin: 0 0;
                            transform: skewY(40deg);
                        }
                    }

                    &-blue {
                        position absolute
                        top 0
                        left 0

                        height 100%;
                        width: 100%;

                        background: linear-gradient(to bottom, #1171c5, #FFFFFF)
                        bottom: 0;
                        margin-left: 12px;
                        transform-origin: 0 0;
                        transform: skewY(-20deg) translateZ(0);

                        opacity 0
                        will-change opacity
                        transition opacity 0.6s $ease-out-quart

                        .is-active & {
                            opacity 1
                        }

                        // bar top side
                        &:before {
                            content ""
                            position: absolute;
                            bottom: 100%;
                            left: 0;

                            background-color #0780dd

                            transform-origin: 0 100%;
                            transform: skewX(50deg);
                            width: 100%;
                            height 10px
                        }

                        // bar right side
                        &:after {
                            content: '';
                            background: linear-gradient(to bottom, #106dbf, #FFFFFF)
                            bottom: 10px;
                            height: 100%;
                            left: -12px;
                            position: absolute;
                            width: 12px;
                            transform-origin: 0 0;
                            transform: skewY(40deg);
                        }
                    }
                }

                &-legend {
                    opacity 0
                    transform translateZ(0)
                    transition none
                    will-change opacity

                    &.is-active {
                        opacity 1
                        transition opacity 0.6s $ease-out-quart
                    }
                }
            }

            &-number {
                position relative
                line-height 1
                top 3px

                sup {
                    top -0.5em
                    font-size 60%
                }
            }

            &-label {
                line-height 1.143
                letter-spacing 0.05em
            }
        }

        &-download {
           &-hover {
                overflow hidden

                will-change transform
                transform translateY(100%) translateZ(0)
                transition transform 0.8s $ease-out-quart

                &-inner {
                    will-change transform
                    transform translateY(-100%) translateZ(0)
                    transition transform 0.8s $ease-out-quart
                }
            }

            &:hover {
                .c-SectionFive-download-hover {
                    transform translateY(0%) translateZ(0)

                    &-inner {
                        transform translateY(0%) translateZ(0)
                    }
                }
            }

            &.is-active, &.is-active:hover {
                 .c-SectionFive-download-hover {
                    transform translateY(0%) translateZ(0)
                    transition transform 0.2s $ease-out-quart

                    &-inner {
                        transform translateY(0%) translateZ(0)
                        transition transform 0.2s $ease-out-quart
                    }
                }
            }

            &-content {
                padding 120px 0
            }
        }
    }
</style>